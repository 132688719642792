<template>
	<div>
		<Header2022 :navbg='true'></Header2022>
		<div class="content">
			<div class="avatar-warp" @click="toolClick('memberInfo')">
				<img :src="user_info.avatar" class="avatar" v-if="user_info.avatar">
				<img src="../../assets/images/touxiang.png" class="avatar" v-else>
			</div>
			<div class="page">
				<div class="name-warp">
					<div class="nickname-warp">
						<div class="nickname">{{user_info.nick_name}}</div>
						<el-button type="primary" class="btn" id="sign" @click="signIn">签到</el-button>
					</div>
					<div class="score-warp">
						<div class="left">
							<div class="title">{{user_info.group_name}}</div>
							<img src="../../assets/images/star.svg" class="icon" v-for="i in user_info.star" :key="i">
						</div>
						<div class="right">
							<div class="title">
								邻里值
							</div>
							<div class="score" @click="toolClick('integralHistory')">
								{{user_info.reward}}
								<i class="iconfont icon-shuoming"></i>
							</div>
						</div>
					</div>

				</div>

				<div class="bottom">
					<div class="service left">
						<div class="title">
							<i class="iconfont icon-zhuanshukefu"></i>
							<div>专属客服</div>
						</div>

						<a :href="customer_service.url" target="_blank" v-if="customer_service">
							<div class="warp">
								<div class="img">
									<img :src="customer_service.service_avatar" v-if="customer_service.service_avatar">
									<img src="../../assets/images/kefu.png" v-else>
								</div>
								<div class="right-warp">
									<div class="top">
										<div class="title-warp">{{customer_service.service_nickname}}</div>
									</div>
									<div class="desc">您好，我是小院客服，很高兴为您服务</div>
								</div>
							</div>
						</a>

						<div class="img-warp">
							<img src="../../assets/images/aijia-banner.jpg">
						</div>
					</div>

					<div class="tool-warp">
						<div class="tool-list">
							<div class="tool" v-for="(item,index) in toolList" :key="index" @click="menuTap(item.link)">
								<div class="iconfont" :class="item.icon"></div>
								<div class="title">{{item.name}}</div>
							</div>
						</div>

						<div class="invite-warp">
							<div class="title">
								<i class="iconfont icon-xingzhuang1"></i>
								<div>邀请好友</div>
							</div>

							<div class="invite">
								<div class="input">{{shareUrl}}</div>
								<el-link type="primary" class="copy" @click="copy" :underline="false">复制链接</el-link>

							</div>

							<div class="desc">
								复制链接，快去邀请好友吧！
							</div>
						</div>
					</div>

				</div>

				<div class="login-btn">
					<el-link type="primary" @click="loginOut">退出登录</el-link>
				</div>

			</div>

		</div>
		<Footer2022></Footer2022>
	</div>
</template>

<script>
	import {
		mapState
	} from 'vuex'
	import Header2022 from '@/components/header2022.vue'
	import Footer2022 from '@/components/footer2022.vue'
	import utils from '@/common/utils'
	import Clipboard from 'clipboard'
	export default {
		data() {
			return {
				toolList: [{
						name: ' 兑换中心',
						icon: 'icon-duihuanzhongxin',
						link: {
							pathName: 'exchange',
						}
					},
					{
						name: ' 我的预约',
						icon: 'icon-peoplelist',
						link: {
							pathName: 'appointment',
							params: 'order'
						}
					},
					{
						name: ' 优惠劵',
						icon: 'icon-wodeyouhuijuan',
						link: {
							pathName: 'couponList',
						}
					},
					{
						name: ' 投诉建议',
						icon: 'icon-lianxixiaoyuan',
						link: {
							pathName: 'feedback',
						}
					},
					{
						name: ' 个人资料',
						icon: 'icon-wentifankui',
						link: {
							pathName: 'memberInfo',
						}
					},
					{
						name: ' 我的设计',
						icon: 'icon-creative',
						link: {
							// url:'http://xy.xiaoyuan.com.cn/index.php?ctl=uc_designs#',//test
							url: 'http://mall.xiaoyuan.com.cn/index.php?ctl=uc_designs#', //formal
						}
					},
					{
						name: '家居优惠券',
						icon: 'icon-round_ticket',
						link: {
							// url:'http://xy.xiaoyuan.com.cn/index.php?ctl=uc_youhui#',//test
							url: 'http://mall.xiaoyuan.com.cn/index.php?ctl=uc_youhui#', //formal
						}
					},
					{
						name: ' 家居订单',
						icon: 'icon-dingdanguanli',
						link: {
							// url:'http://xy.xiaoyuan.com.cn/index.php?ctl=uc_order#',//test
							url: 'http://mall.xiaoyuan.com.cn/index.php?ctl=uc_order#', //formal
						}
					},
					{
						name: ' 商户付款',
						icon: 'icon-shop',
						link: {
							// url:'http://xy.xiaoyuan.com.cn/index.php?ctl=uc_supplierpay#',//test
							url: 'http://mall.xiaoyuan.com.cn/index.php?ctl=uc_supplierpay#', //formal
						}
					},
				]
			}
		},
		components: {
			Header2022,
			Footer2022,
		},
		computed: {
			...mapState(['user_info', 'ocApi', 'mallApi', 'customer_service']),
			shareUrl: function() {
				return "http://xiaoyuan.com.cn/?promotion_id=2&invitation_customer_id=" + this.user_info.customer_id
			},
		},
		methods: {
			loginOut: utils.debounce(function() {
				let that = this;
				that.$message({
					message: '退出成功',
					type: 'success'
				});
				that.$axios.get(that.mallApi, {
					params: {
						ctl: 'user',
						act: 'xyloginout'
					}
				})
				that.$store.dispatch('loginLogout', null)
				setTimeout(() => {
					that.$router.replace({
						name: 'index'
					});
				}, 1000);
			}, 500, true),
			menuTap(e) {
				if (e.url) {
					window.open(e.url)
					return;
				}
				if (e.pathName == this.$route.name) {
					this.reload()
				}
				this.$router.push({
					name: e.pathName,
					params: {
						paramsName: e.params,
					}
				});
			},
			toolClick: utils.debounce(function(name, fields) {
				this.$router.push({
					name,
					params: {
						tagName: fields,
					}
				})
			}, 200, true),
			signIn: utils.debounce(function() {
				let that = this
				that.$axios.post(that.ocApi + 'user/sign', {
						"telephone": that.user_info.telephone
					})
					.then(res => {
						if (res.data.code == 1) {
							this.$message({
								message: res.data.message,
								type: 'success'
							});
							that.$store.dispatch('updateMember')
						} else {
							that.$message.error(res.data.message);
						}
					})
					.catch(error => {
						that.$message.error(error.response.data.message);
					});
			}, 200, true),
			copy: utils.debounce(function() {
				let shareUrl = this.shareUrl
				var clipboard = new Clipboard('.copy', {
					text: function() {
						return shareUrl
					}
				})
				// 复制成功
				clipboard.on('success', () => {
					this.$message({
						message: '复制成功，快去邀请好友吧！',
						type: 'success'
					});
					// 释放内存
					clipboard.destroy()
				})
				// 复制失败
				clipboard.on('error', () => {
					// 不支持复制
					this.$message.error('复制失败，请尝试重新复制！');
					// 释放内存
					clipboard.destroy()
				})

			}, 200, true),
		},
	}
</script>

<style lang="scss" scoped>
	.content {
		width: 100%;
		margin: auto;
		background-color: #f7f7f7;
		color: #434343;
	}

	.avatar-warp {
		width: 100%;
		background-color: #fff;
		position: relative;
		height: 220px;
	}

	.avatar-warp img {
		position: absolute;
		bottom: -60px;
		width: 142px;
		height: 142px;
		border-radius: 100%;
		border: 4px solid #dddddd;
		left: 50%;
		margin-left: -71px;
	}

	.content .page .name-warp {
		margin-top: 72px;
		width: 100%;
	}

	.content .page .name-warp .nickname-warp {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.content .page .name-warp .nickname-warp .nickname {
		font-size: 24px;
	}

	.content .page .name-warp .nickname-warp .btn {
		font-size: 14px;
		padding: 6px 16px;
		border-radius: 5px;
		margin-left: 10px;
	}

	.content .page .name-warp .score-warp {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 10px;
	}

	.page .name-warp .score-warp .left {
		display: flex;
		align-items: center;
		width: 153px;
	}

	.content .page .name-warp .score-warp .title {
		font-size: 14px;
		margin-right: 7px;
	}

	.content .page .name-warp .score-warp .left .icon {
		width: 15px;
		height: 15px;
		margin-right: 3px;
	}

	.content .page .name-warp .score-warp .right {
		display: flex;
		align-items: center;
		margin-left: 20px;
	}

	.content .page .name-warp .score-warp .right .score {
		color: #00cb87;
	}

	.content .page .bottom {
		display: flex;
		justify-content: space-between;
		margin-top: 60px;
	}

	.content .page .bottom .left {
		width: 330px;
		height: 344px;
		background-color: #fff;
		border-bottom: 1px solid #f7f7f7;
	}

	.content .page .bottom .left .title {
		height: 59px;
		width: 100%;
		padding: 0 24px;
		display: flex;
		align-items: center;
		box-sizing: border-box;
		font-size: 18px;
		border-bottom: 1px solid #f7f7f7;
	}

	.content .page .bottom .left .title .iconfont {
		font-size: 23px;
		color: #00cb87;
		margin-right: 11px;
	}

	.content .page .bottom .left .warp {
		padding: 20px 24px 12px;
		display: flex;
		height: 105px;
		box-sizing: border-box;
	}

	.content .page .bottom .left .warp .img {
		width: 73px;
		height: 73px;
		border-radius: 100%;
	}

	.content .page .bottom .left .warp .img img {
		width: 73px;
		height: 73px;
		border-radius: 100%;
	}

	.content .page .bottom .left .warp .right-warp {
		margin-left: 9px;
		flex: 1;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.content .page .bottom .left .warp .right-warp .top {
		display: flex;
		font-size: 17px;
		justify-content: space-between;
	}

	.content .page .bottom .left .warp .right-warp .top .time {
		font-size: 14px;
		color: #999999;
	}

	.content .page .bottom .left .warp .right-warp .desc {
		font-size: 16px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 100%;
		color: #999999;
		margin-top: 16px;
	}

	.content .page .bottom .left .img-warp {
		width: 100%;
		padding: 0 24px 32px;
		box-sizing: border-box;
	}

	.content .page .bottom .left .img-warp img {
		width: 100%;
		height: 147px;
	}

	.content .page .bottom .tool-warp {
		flex: 1;
		margin-left: 20px;
	}

	.content .page .bottom .tool-list {
		background-color: #fff;
		flex-wrap: wrap;
		display: flex;
	}

	.content .page .bottom .tool-list .tool {
		width: 169px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		border-left: 1px solid #f7f7f7;
		border-bottom: 1px solid #f7f7f7;
		padding: 15px 0;
	}

	.content .page .bottom .tool-list .tool .iconfont {
		font-size: 50px;
		text-align: center;
	}

	.content .page .bottom .tool-list .tool .title {
		font-size: 18px;
		margin-top: 40px;
	}

	.content .page .bottom .tool-warp .invite-warp {
		flex: 1;
		height: 152px;
		margin-top: 20px;
		background-color: #fff;
		padding: 20px 26px 30px;
		box-sizing: border-box;
	}

	.content .page .bottom .tool-warp .invite-warp .title {
		display: flex;
		font-size: 18px;
		color: #000;
	}

	.content .page .bottom .tool-warp .invite-warp .title .iconfont {
		font-size: 20px;
		margin-right: 6px;
		color: #00cb87;
	}

	.content .page .bottom .tool-warp .invite {
		margin-top: 20px;
		display: flex;
		justify-content: space-between;
		height: 40px;
	}

	.content .page .bottom .tool-warp .invite .input {
		flex: 1;
		background-color: #f7f7f7;
		display: flex;
		align-items: center;
		padding-left: 21px;
		box-sizing: border-box;
	}

	.content .page .bottom .tool-warp .invite .copy {
		font-size: 16px;
		color: #00cb87;
		margin-left: 26px;
		display: flex;
		align-items: center;
	}

	.content .page .bottom .tool-warp .desc {
		padding: 10px 0 0 21px;
	}

	.content .page .login-btn {
		width: 100%;
		margin-top: 30px;
		text-align: center;
		font-size: 16px;
		padding-bottom: 51px;
	}
</style>